
import { defineComponent } from "vue";
import downloadService from "@/services/download.service";
import { useRoute } from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "DownloadFile",
  setup() {
    downloadService
      .download(`/${useRoute().params.url.toString()}`, "test", "zip")
      .then(() => {
        router.push({ name: "parcels.list" });
      });
  },
});
